import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil, startWith, distinctUntilChanged } from 'rxjs/operators';
import { CountriesService } from 'app/services/countries.service';
import { CommonService } from 'app/services/common.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ListItem } from 'app/model/entities/baseEntity';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss']
})
export class CountryInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() countryControl: FormControl;
  @Input() stateControl: FormControl;
  @Input() countries: ListItem[];
  @Input() showState = true;

  states: any[] = [];
  filteredCountries: any[];
  filteredStates: any[];
  autoSet = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(public countriesService: CountriesService, public commonService: CommonService) {}

  ngOnInit(): void {
    this.countryControl.valueChanges
      .pipe(
        startWith(this.countryControl.value),
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged()
      )
      .subscribe((country) => {
        // Skip setting state when it is setting default value so that when country-input is initialized
        // after countryControl and stateCountrol are set default value, the default state value won't be overwritten
        if (this.autoSet || (!this.countryControl.value && !this.stateControl.value)) {
          this.stateControl.disable();
          this.stateControl.setValue(null);
        } else {
          this.autoSet = true;
        }
        if (country && country.id) {
          this.loadStates(country.id);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countries && changes.countries.currentValue !== changes.countries.previousValue) {
      this.filteredCountries = CommonService.filterOptions(null, this.countries);
    }
  }

  ngOnDestroy(): void {
    this.stateControl.enable();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCountrySelectChange(index: number) {
    const country = this.filteredCountries[index];
    this.countryControl.setValue(country);
  }

  onStateSelectChange(index: number) {
    const state = this.filteredStates[index];
    this.stateControl.setValue(state);
  }

  private loadStates(countryId): void {
    this.countriesService
      .loadStates(countryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((states: ListItem[]) => {
        this.states = states;
        this.filteredStates = CommonService.filterOptions(null, this.states);
        if (this.states && this.states.length) {
          this.stateControl.enable();
        } else {
          this.stateControl.disable();
        }
      });
  }
}
