<owc-modal-dialog disable-backdrop-click disable-escape-keydown [(ngModel)]="visible" class="notification-message-popup-container">
  <ng-container *ngIf="!(isNotificationLoading$ | async); else spinner">
    <div class="notification-message-content" *ngFor="let notificationMessage of (notificationMessages$ | async)">
      <owc-typography element="h3" variant="title6">
        {{notificationMessage.header}}
      </owc-typography>
      <div class="content-texts" [innerHTML]="notificationMessage.body">
      </div>
    </div>
  </ng-container>

  <div slot="actions" class="actions" [class.hide]="isNotificationLoading$ | async">
    <owc-button (click)="close()" i18n="NotificationMessagePopupComponent.Button|Text for close button@@close">Close</owc-button>
  </div>
</owc-modal-dialog>

<ng-template #spinner>
  <owc-progress-spinner [diameter]="100" [value]="100"></owc-progress-spinner>
</ng-template>
