import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, finalize, Observable, of, tap } from 'rxjs';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import { NotificationMessage } from 'app/model/entities/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notificationLoading = new BehaviorSubject<boolean>(false);
  private _dismissNotificationUrl = new BehaviorSubject<string>(null);
  private _notificationMessages = new BehaviorSubject<NotificationMessage[]>(null);

  get isNotificationLoading$(): Observable<boolean> {
    return this._notificationLoading.asObservable();
  }

  get canCreateNotification$(): Observable<boolean> {
    return this.authService.hasPermission$(SessionLinkKeys.UPSERT_NOTIFICATION);
  }

  get showNotifications$(): Observable<boolean> {
    return this.authService.hasPermission$(SessionLinkKeys.GET_NOTIFICATION);
  }

  get notificationMessages$(): Observable<NotificationMessage[]> {
    return this._notificationMessages.asObservable();
  }

  get isNotificationAcknowledged$(): Observable<boolean> {
    return of(localStorage.getItem('notificationAcknowledged') === 'true');
  }

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private toasterService: ToasterService
  ) {}

  setNotificationAcknowledgement(): void {
    localStorage.setItem('notificationAcknowledged', 'true');
  }

  uploadNotificationMessageFile$(messageFile: File): Observable<null | HttpErrorResponse> {
    const upsertNotificationUrl = this.authService.getURL(SessionLinkKeys.UPSERT_NOTIFICATION);

    if (!upsertNotificationUrl) {
      return of(null);
    }

    return this.http.post<null | HttpErrorResponse>(upsertNotificationUrl, messageFile).pipe(
      tap({
        next: () => {
          this.toasterService.success('SUCCESS');
        },
        error: () => {
          this.toasterService.error('ERROR');
        }
      })
    );
  }

  loadNotifications(): void {
    const getNotificationsUrl = this.authService.getURL(SessionLinkKeys.GET_NOTIFICATION);

    if (!getNotificationsUrl) {
      return;
    }

    this._notificationLoading.next(true);

    this.http
      .get<NotificationMessage[] | HttpErrorResponse>(getNotificationsUrl)
      .pipe(
        tap({
          next: (notificationMessages: NotificationMessage[]) => {
            this._notificationMessages.next(notificationMessages);
            this._dismissNotificationUrl.next(
              this.authService.getURL(SessionLinkKeys.DISMISS_NOTIFICATION)
            );
          },
          error: () => {
            this.toasterService.error('ERROR');
          }
        }),
        finalize(() => this._notificationLoading.next(false))
      )
      .subscribe();
  }

  dismissNotificationsEvent(): void {
    const dismissNotificationUrl = this._dismissNotificationUrl.getValue();

    if (!dismissNotificationUrl) {
      return;
    }

    const notificationIds = this._notificationMessages.getValue()?.map((message) => ({
      notificationId: message.notificationId
    }));

    this.http
      .patch(dismissNotificationUrl, notificationIds)
      .pipe(
        tap({
          error: () => {
            this.toasterService.error('ERROR');
          }
        })
      )
      .subscribe();
  }
}
