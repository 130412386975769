export enum CaseLinkKeys {
  APPROVE_CASE = 'approveCase',
  CANCEL_CASE = 'cancelCase',
  CREATE_REVISION = 'createRevision',
  CHECK_START_ANALYSIS = 'checkStartAnalysis',
  CHECK_STATUS = 'checkStatus',
  CREATE_LAB_NOTE = 'createLabNote',
  DELETE_CASE = 'deleteCase',
  EDIT_CASE = 'editCase',
  EDIT_CASE_CLINICAL = 'editCaseClinical',
  EDIT_CASE_DISPLAY_SETTINGS = 'editCaseDisplaySettings',
  EDIT_ORDERER = 'editOrderer',
  EDIT_SUBSCRIBERS_CASE = 'editSubscribersCase',
  PREVIEW_REPORT = 'previewReport',
  REJECT_CASE = 'rejectCase',
  SUBMIT_CASE_FOR_APPROVAL = 'submitCaseForApproval',
  UPDATE_VIRTUAL_GENE_PANEL = 'updateVirtualGenePanel',
  UPLOAD = 'upload',
  VIEW_ASSAY = 'viewAssay',
  VIEW_CASE = 'viewCase',
  VIEW_CASE_PAGINATED_RESPONSE = 'viewCasePaginatedResponse',
  VIEW_CASE_RESULTS = 'viewCaseResults',
  VIEW_CASE_DISPLAY_SETTINGS = 'viewCaseDisplaySettings',
  VIEW_LAB_NOTES = 'viewLabNotes',
  VIEW_PATIENT = 'viewPatient',
  VIEW_PATIENT_REVISION = 'viewPatientRevision',
  VIEW_SUBSCRIBERS_CASE = 'viewSubscribersCase',
  VIEW_CASE_UNPROCESSED_VARIANTS_SETTINGS = 'viewCaseUnprocessedVariantsSettings',
  VIEW_CASE_CHANGE_DIAGNOSIS_DATA = 'viewCaseChangeDiagnosisData'
}

export enum PaginatedCaseResponseKeys {
  SUMMARY = 'summary',
  VARIANTS = 'variants',
  PERTINENT_NEGATIVES = 'pertinentNegatives',
  UNPROCESSED_VARIANTS = 'unprocessedVariants',
  OTHER_BIOMARKERS = 'otherBiomarkers',
  ATTRIBUTES = 'attributes'
}

export const MAX_PAGINATION_SIZE: number = 999999999;
