import { of, BehaviorSubject, Observable, timer, forkJoin, combineLatest, EMPTY } from 'rxjs';
import {
  map,
  finalize,
  tap,
  catchError,
  filter,
  mergeMap,
  retry,
  mapTo,
  first,
  timeout
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { sortBy as _sortBy, has as _has, get as _get } from 'lodash';

import { AuthService } from 'app/services/auth.service';
import {
  AssaySummary,
  Assay,
  VgpMismatchError,
  VirtualGenePanel,
  VgpFileParsingError,
  DiseaseTmbMsiThresholdsSet,
  Threshold,
  DiseaseTmbMsiHrdParserResponse
} from 'app/model/entities/assay';
import { PertinentNegative, PertinentNegativeMigration } from '../model/entities/pertinentNegative';
import { VariantTag, VariantTagError } from 'app/model/entities/varaintTag';
import { Logo, createLogoSrc } from 'app/model/entities/logo';
import { FileToUpload } from 'app/model/entities/fileToUpload';
import { FileUploadService } from './file-upload.service';
import { SecondaryAnalysisFileFormat } from 'app/model/entities/secondaryAnalysisFileFormat';
import { Diagnosis } from 'app/model/entities/case';
import { TmbMsiThresholdErrorType } from 'app/model/valueObjects/tmbMsiThreshold';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

const SECONDARY_FORMAT_INTERVAL = 5000;
const SECONDARY_FORMAT_TIMEOUT = 300000;

@Injectable()
export class AssayService {
  private _genomes: string[];
  private _genomesLoading = new BehaviorSubject<boolean>(false);

  private _assayList = new BehaviorSubject<AssaySummary[]>([]);
  private _loadingAssayList = new BehaviorSubject<boolean>(false);
  private _assayListError = new BehaviorSubject<boolean>(false);

  private _currentLogo = new BehaviorSubject<string>(null);
  private _loadingCurrentLogo = new BehaviorSubject<boolean>(false);

  private _currentAssay = new BehaviorSubject<Assay>(null);
  private _currentAssayLoading = new BehaviorSubject<boolean>(false);
  private _currentAssayError = new BehaviorSubject<boolean>(false);

  private _assayNames = new BehaviorSubject<any[]>([]);
  private _loadingAssayNames = new BehaviorSubject<boolean>(false);
  private _assayNamesError = new BehaviorSubject<boolean>(false);

  private _savingAssay = new BehaviorSubject<boolean>(false);

  private _migratingPertinentNegatives = new BehaviorSubject<boolean>(false);

  private _parsedVariantTags = new BehaviorSubject<VariantTag[]>([]);
  private _variantTagsParsing = new BehaviorSubject<boolean>(false);
  private _parsedVariantTagsError = new BehaviorSubject<VariantTagError[]>([]);

  private _detectingFileFormat = new BehaviorSubject<boolean>(false);
  private _errorDetectingFormat = new BehaviorSubject<boolean>(false);

  private _checkingVGPCompatibility = new BehaviorSubject<boolean>(false);
  private _vgpMismatchError = new BehaviorSubject<VgpMismatchError[]>([]);
  private _uploadingVGPFile = new BehaviorSubject<boolean>(false);
  private _uploadedGenePanel = new BehaviorSubject<VirtualGenePanel>(null);
  private _vgpFileParsingErrors = new BehaviorSubject<VgpFileParsingError>(null);
  private _parsingTMBMSIThresholds = new BehaviorSubject<boolean>(false);
  private _doidCompareLoading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get assayList(): Observable<AssaySummary[]> {
    return this._assayList.asObservable();
  }

  get loadingAssayList(): Observable<boolean> {
    return this._loadingAssayList.asObservable();
  }

  get assayListError(): Observable<boolean> {
    return this._assayListError.asObservable();
  }

  get currentLogo(): Observable<string> {
    return this._currentLogo.asObservable();
  }

  get currentAssay(): Observable<Assay> {
    return this._currentAssay.asObservable();
  }

  get currentAssayLogo(): Observable<string> {
    return this.currentAssay.pipe(
      map((currentAssay) => createLogoSrc(_get(currentAssay, 'reportTemplate')))
    );
  }

  get currentAssayLoading(): Observable<boolean> {
    return this._currentAssayLoading.asObservable();
  }

  get currentAssayError(): Observable<boolean> {
    return this._currentAssayError.asObservable();
  }

  get assayNames(): Observable<any[]> {
    return this._assayNames.asObservable();
  }

  get loadingAssayNames(): Observable<boolean> {
    return this._loadingAssayNames.asObservable();
  }

  get assayNamesError(): Observable<boolean> {
    return this._assayNamesError.asObservable();
  }

  get genomesLoading(): Observable<boolean> {
    return this._genomesLoading.asObservable();
  }

  get parsingTMBMSIThresholds(): Observable<boolean> {
    return this._parsingTMBMSIThresholds.asObservable();
  }

  get doidCompareLoading(): Observable<boolean> {
    return this._doidCompareLoading.asObservable();
  }

  get savingAssay(): Observable<boolean> {
    return this._savingAssay.asObservable();
  }

  get migratingPertinentNegatives(): Observable<boolean> {
    return this._migratingPertinentNegatives.asObservable();
  }

  get parsedVariantTags(): Observable<VariantTag[]> {
    return this._parsedVariantTags.asObservable();
  }

  get parsedVariantTagsError(): Observable<VariantTagError[]> {
    return this._parsedVariantTagsError.asObservable();
  }

  get variantTagsParsing(): Observable<boolean> {
    return this._variantTagsParsing.asObservable();
  }

  get detectingFileFormat(): Observable<boolean> {
    return this._detectingFileFormat.asObservable();
  }

  get errorDetectingFormat(): Observable<boolean> {
    return this._errorDetectingFormat.asObservable();
  }

  get checkingVGPCompatibility(): Observable<boolean> {
    return this._checkingVGPCompatibility.asObservable();
  }

  get vgpMismatchErrors(): Observable<VgpMismatchError[]> {
    return this._vgpMismatchError.asObservable();
  }

  get uploadingVGPFile(): Observable<boolean> {
    return this._uploadingVGPFile.asObservable();
  }

  get uploadedGenePanel(): Observable<VirtualGenePanel> {
    return this._uploadedGenePanel.asObservable();
  }

  get vgpFileParsingErrors(): Observable<VgpFileParsingError> {
    return this._vgpFileParsingErrors.asObservable();
  }

  get assayThresholdDOIDList$(): Observable<Threshold[]> {
    return this.currentAssay.pipe(
      filter((currentAssay) => !!currentAssay),
      map((currentAssay) => {
        if (
          currentAssay?.biomarkerFileReaders &&
          currentAssay?.diseaseTmbMsiThresholdsSet?.content
        ) {
          return currentAssay.diseaseTmbMsiThresholdsSet.content;
        }
        return null;
      })
    );
  }

  getMissingAssayThreshold$(
    selectedDiagnosis$: Observable<Diagnosis>
  ): Observable<TmbMsiThresholdErrorType> {
    return combineLatest([selectedDiagnosis$, this.assayThresholdDOIDList$]).pipe(
      map(([diagnosis, assayThresholdDOIDList]) => {
        if (!diagnosis?.externalId || !assayThresholdDOIDList) {
          return null;
        }

        const selectedThresholdData = assayThresholdDOIDList?.find(
          (threshold: Threshold) => threshold.doid === diagnosis?.externalId
        );

        if (selectedThresholdData?.doid) {
          return this.getTmbMsiErrorType(selectedThresholdData);
        }
        return TmbMsiThresholdErrorType.TMB_MSI;
      })
    );
  }

  getTmbMsiErrorType(threshold: Threshold): TmbMsiThresholdErrorType {
    const { msiHighThreshold, msiLowThreshold, tmbHighThreshold, tmbIntermediateThreshold } =
      threshold;

    if (!msiHighThreshold && !msiLowThreshold && !tmbHighThreshold && !tmbIntermediateThreshold) {
      return TmbMsiThresholdErrorType.TMB_MSI;
    } else if (!msiHighThreshold && !msiLowThreshold) {
      return TmbMsiThresholdErrorType.MSI;
    } else if (!tmbHighThreshold && !tmbIntermediateThreshold) {
      return TmbMsiThresholdErrorType.TMB;
    }
    return null;
  }

  getCurrentAssayLink(linkKey: string): string {
    return this._currentAssay.value._links[linkKey].href;
  }

  hasPermission(permissionToVerify: string): boolean {
    return _has(this._currentAssay.value._links, permissionToVerify);
  }

  loadAssayList() {
    this._loadingAssayList.next(true);

    this.http
      .get<any>(this.authService.getURL(SessionLinkKeys.LIST_ASSAYS))
      .pipe(finalize(() => this._loadingAssayList.next(false)))
      .subscribe({
        next: (json: any) => {
          this._assayList.next(json['_embedded'].assaySummaries);
          this._assayListError.next(false);
        },
        error: () => this._assayListError.next(true)
      });
  }

  clearCurrentAssay() {
    this._currentAssay.next(null);
  }

  loadAssay(assayId: string): void {
    if (!assayId) {
      return;
    }
    this._currentAssayLoading.next(true);
    this.clearCurrentAssay();

    this.http
      .get<any>(this.authService.getURL(SessionLinkKeys.VIEW_ASSAY, { assayId }))
      .pipe(finalize(() => this._currentAssayLoading.next(false)))
      .subscribe({
        next: (json: Assay) => {
          this._currentAssay.next(json);
          this._currentAssayError.next(false);
        },
        error: () => this._currentAssayError.next(true)
      });
  }

  getAssay$(assayId: string): Observable<Assay | null> {
    if (!assayId) {
      return of(null);
    }

    return this.http
      .get<Assay>(this.authService.getURL(SessionLinkKeys.VIEW_ASSAY, { assayId: assayId }))
      .pipe(catchError(() => of(null)));
  }

  loadCurrentVersionAssay(assayLink: string): void {
    if (!assayLink) {
      return;
    }
    this._currentAssayLoading.next(true);
    this.clearCurrentAssay();
    this.http
      .get<Assay | HttpErrorResponse>(assayLink)
      .pipe(
        tap((currentAssayData: Assay) => {
          this._currentAssay.next(currentAssayData);
          this._currentAssayError.next(false);
        }),
        catchError(() => {
          this._currentAssayError.next(true);
          return EMPTY;
        }),
        finalize(() => this._currentAssayLoading.next(false))
      )
      .subscribe();
  }

  loadAssayNames() {
    this._loadingAssayNames.next(true);

    this.http
      .get<any>(this.authService.getURL(SessionLinkKeys.LIST_ASSAY_NAMES))
      .pipe(
        map((json: any) => _sortBy(json, 'value')),
        finalize(() => this._loadingAssayNames.next(false))
      )
      .subscribe({
        next: (json: any) => {
          this._assayNames.next(json);
          this._assayNamesError.next(false);
        },
        error: () => this._assayNamesError.next(true)
      });
  }

  // Use this endpoint to load the most recent logo, if any.
  // Due to this requirement:
  //
  // "Use the logo from the currently selected assay.
  // If the all assays are selected, use the logo from the most
  // recently created assay that has a logo. If there are no logos -
  // omit the image. "
  loadCurrentLogo() {
    this._loadingCurrentLogo.next(true);

    this.http
      .get<Logo>(this.authService.getURL(SessionLinkKeys.VIEW_ORG_LOGO))
      .pipe(finalize(() => this._loadingCurrentLogo.next(false)))
      .subscribe((json: any) => {
        this._currentLogo.next(createLogoSrc(json));
      });
  }

  loadGenomes() {
    if (this._genomes) {
      return of(this._genomes);
    } else {
      this._genomesLoading.next(true);

      // TODO: load this from the API endpoint when it is implemented
      return of(['GRCh38', 'GRCh37', 'hg38', 'hg19']).pipe(
        tap((json: string[]) => (this._genomes = json)),
        finalize(() => this._genomesLoading.next(false))
      );
    }
  }

  saveAssay(assayData, assayId: string): Observable<Assay> {
    this._savingAssay.next(true);

    let request;

    if (assayId) {
      request = this.http.put<any>(this.getCurrentAssayLink(SessionLinkKeys.EDIT_ASSAY), assayData);
    } else {
      request = this.http.post<any>(
        this.authService.getURL(SessionLinkKeys.CREATE_ASSAY),
        assayData
      );
    }

    return request.pipe(finalize(() => this._savingAssay.next(false)));
  }

  migratePertinentNegatives(
    versionFrom: string,
    versionTo: string,
    oldTranscriptome: string,
    newTranscriptome: string,
    pertinentNegatives: PertinentNegative[]
  ): Observable<PertinentNegativeMigration[]> {
    this._migratingPertinentNegatives.next(true);
    return this.http
      .post<PertinentNegativeMigration[]>(
        this.authService.getURL(SessionLinkKeys.MIGRATE_PERTINENT_NEGATIVE_BIOMARKERS),
        {
          fromVersion: versionFrom,
          toVersion: versionTo,
          fromTranscriptome: oldTranscriptome.toLowerCase(),
          toTranscriptome: newTranscriptome.toLowerCase(),
          pertinentNegatives
        }
      )
      .pipe(finalize(() => this._migratingPertinentNegatives.next(false)));
  }

  parseVariantTag(variantTagCSV: FormData) {
    this._variantTagsParsing.next(true);
    this._parsedVariantTagsError.next([]);
    return this.http
      .post(this.authService.getURL(SessionLinkKeys.PARSE_VARIANT_TAGS), variantTagCSV)
      .pipe(
        tap((result) => {
          if (result && result['_embedded']) {
            const variantTags = result['_embedded']['variantTags'].map(
              (variantTag) => new VariantTag(variantTag)
            );
            this._parsedVariantTags.next(variantTags);
          }
        }),
        finalize(() => {
          this._variantTagsParsing.next(false);
        }),
        catchError((response) => {
          this.setParseVariantTagError(response);
          return of({});
        })
      );
  }

  clearParseVariantTag() {
    this._parsedVariantTagsError.next([]);
    this._parsedVariantTags.next([]);
  }

  private setParseVariantTagError(response: any) {
    const responseError = response.error;
    if (!responseError.errors) {
      return;
    }
    this._parsedVariantTagsError.next(responseError.errors.map((err) => new VariantTagError(err)));
  }

  checkDuplicateAssayName(assayName: string): Observable<boolean> {
    return this.http
      .get(this.authService.getURL(SessionLinkKeys.COUNT_BY_ASSAY_NAME), {
        params: new HttpParams().append('assayName', assayName)
      })
      .pipe(map((count: number) => count > 0));
  }

  doidCompare(
    contentVersionFrom: string,
    contentVersionTo: string,
    doids: string[]
  ): Observable<any> {
    this._doidCompareLoading.next(true);

    return this.http
      .post(
        this.authService.getURL(SessionLinkKeys.COMPARE_ROCHE_CONTENT_DISEASES, {
          contentVersionFrom,
          contentVersionTo
        }),
        {
          doids
        }
      )
      .pipe(finalize(() => this._doidCompareLoading.next(false)));
  }

  parseTMBMSIThresholds(csvFile: File, url: string): Observable<DiseaseTmbMsiHrdParserResponse> {
    this._parsingTMBMSIThresholds.next(true);

    // File upload approach adapted from https://stackoverflow.com/a/47597472
    const formData = new FormData();
    formData.append('file', csvFile);

    const requestOptions = {
      params: new HttpParams(),
      reportProgress: false
    };

    const request = new HttpRequest('POST', url, formData, requestOptions);

    return this.http.request<DiseaseTmbMsiHrdParserResponse>(request).pipe(
      filter((event) => event instanceof HttpResponse),
      // Augment with 'sourceFileName' since it is not in response
      map((response: HttpResponse<DiseaseTmbMsiHrdParserResponse>) =>
        Object.assign({}, response.body, { sourceFileName: csvFile.name })
      ),
      finalize(() => this._parsingTMBMSIThresholds.next(false))
    );
  }

  detectOutputFileFormat(fileToUpload: FileToUpload): Observable<SecondaryAnalysisFileFormat> {
    this._detectingFileFormat.next(true);

    return FileUploadService.generateMD5(fileToUpload.file).pipe(
      mergeMap((md5) =>
        forkJoin([
          this.http.post(this.authService.getURL(SessionLinkKeys.DETECT_OUTPUT_FILE_FORMAT), {
            file: { fileName: fileToUpload.name, md5 }
          }),
          of(md5)
        ])
      ),
      mergeMap(([response, md5]) =>
        this.http
          .put(response['_links'].upload.href, fileToUpload.file, {
            headers: new HttpHeaders().set('Content-MD5', md5),
            responseType: 'text'
          })
          .pipe(retry(1), mapTo(response['_links'].signalUpload.href))
      ),
      mergeMap((singnalUploadUrl: string) => this.http.post(singnalUploadUrl, {})),
      mergeMap((response) =>
        timer(0, SECONDARY_FORMAT_INTERVAL) // Polling approach: https://stackoverflow.com/a/40884517/359001
          .pipe(
            mergeMap(() => this.http.get<any>(response['_links'].self.href)),
            first((result: any) => ['SUCCESS', 'ERROR'].includes(result.status)),
            map((result: any) => {
              if (result.status === 'SUCCESS') {
                // The 'id' here is really the 'fileFormatId', so make that swap
                const secondaryAnalysisFileFormat = Object.assign(
                  {},
                  result.fileFormat,
                  {
                    fileFormatId: result.fileFormat.id
                  },
                  {
                    _links: result._links
                  }
                );
                delete secondaryAnalysisFileFormat.id;
                return secondaryAnalysisFileFormat;
              } else {
                throw new Error('File format processing error');
              }
            }),
            timeout(SECONDARY_FORMAT_TIMEOUT)
          )
      ),
      finalize(() => this._detectingFileFormat.next(false))
    );
  }

  checkVGPCompatibility(transcriptomeId: string, vgpIds: string[]) {
    this._vgpMismatchError.next([]);
    this._checkingVGPCompatibility.next(true);
    return this.http
      .post(this.authService.getURL(SessionLinkKeys.VALIDATE_VGP_COMPATIBILITY), {
        transcriptomeId,
        virtualPanelIds: vgpIds
      })
      .pipe(finalize(() => this._checkingVGPCompatibility.next(false)))
      .subscribe({
        next: () => {}, // 204 if all good.
        error: (response: HttpErrorResponse) => this._vgpMismatchError.next(response.error)
      });
  }

  clearVGPErrors() {
    this._vgpMismatchError.next([]);
  }

  uploadVGPFile(uploadFile: FormData, transcriptome: string) {
    this._uploadingVGPFile.next(true);
    this._vgpFileParsingErrors.next(null);
    this._uploadedGenePanel.next(null);

    return this.http
      .post(
        this.authService.getURL(SessionLinkKeys.UPLOAD_VGP_FILE, { id: transcriptome }),
        uploadFile
      )
      .pipe(finalize(() => this._uploadingVGPFile.next(false)))
      .subscribe({
        next: (panel: VirtualGenePanel) => this._uploadedGenePanel.next(panel),
        error: (response: HttpErrorResponse) => this._vgpFileParsingErrors.next(response.error)
      });
  }

  clearVGPMismatchErrorById(virtualGenePanelId: string) {
    let errors: VgpMismatchError[] = this._vgpMismatchError.value;
    errors = errors.filter((e) => e.id !== virtualGenePanelId);
    this._vgpMismatchError.next(errors);
  }
}
