<div>
  <owc-editable-dropdown [formControl]="countryControl" placeholder="Country" i18n-placeholder="CountryInput.AutocompleteInput.Placeholder@@country" auto-complete="off" 
    ngDefaultControl (selectChange)="onCountrySelectChange($event.detail)">
    <owc-list-item *ngFor="let option of filteredCountries" [selected]="option.value === countryControl.value?.value">
      {{option.value}}
    </owc-list-item>
  </owc-editable-dropdown>
  <owc-typography *ngIf="countryControl.touched && countryControl.errors?.required" variant="caption" i18n="Validation for required field|Message to be displayed as a validation@@required-field-validation" class="required-owc-dropdown-error">
    This field is required
  </owc-typography>
</div>
<div *ngIf="showState">
  <owc-editable-dropdown [formControl]="stateControl" placeholder="State" i18n-placeholder="CountryInput.AutocompleteInput.Placeholder@@state" auto-complete="off" 
    ngDefaultControl (selectChange)="onStateSelectChange($event.detail)">
    <owc-list-item *ngFor="let option of filteredStates" [selected]="option.value === stateControl.value?.value">
      {{option.value}}
    </owc-list-item>
  </owc-editable-dropdown>
  <owc-typography *ngIf="stateControl.touched && stateControl.errors?.required"  variant="caption" i18n="Validation for required field|Message to be displayed as a validation@@required-field-validation" class="required-owc-dropdown-error">
    This field is required
  </owc-typography>
</div>
