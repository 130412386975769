import { BehaviorSubject, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  tap
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { get as _get, has as _has } from 'lodash';
import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js';

import { API_GATEWAY_URL } from 'app/model/valueObjects/apiGatewayUrl';
import { AuthUser, Link, Links, UserMapping } from 'app/model/entities/authUser';
import { Localization } from 'app/model/valueObjects/localization';
import { LocaleNumberPipe } from 'app/pipes/locale-number.pipe';
import { DateFormatPipe } from 'app/pipes/date-format-pipe';
import { HelpConfig } from '../model/valueObjects/helpConfig';
import { ToasterService } from 'app/services/toaster.service';
import { upgradeProgressing } from 'app/services/upgrade.service.util';
import { BrowserStorageService } from 'app/services/browser-storage.service';
import { BrowserStoredValue, BrowserStorageType } from '../model/entities/browser-storage';
import { CommonService, Replacements } from './common.service';
import { environment } from 'environments/environment';
import { PlatformRefreshSessionResponse } from 'app/model/entities/navifyPlatformApi';
import { IstFeatureFlags } from 'app/model/valueObjects/featureFlags';
import { role } from 'app/model/entities/user';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { DialogService } from 'app/services/dialog.service';

const REDIRECT_URL_LEGACY_1_0 = 'REDIRECT_URL';

export enum BrowserStoreKeys {
  /**
   * Object with userIDs for keys and true if they have turned on PHI screening for support.
   */
  HidePHI = 'hidePHI',
  /**
   * String URL to keep track of where the user was trying to go before we redirected for AuthN.
   */
  RedirectUrl = 'redirectUrl',
  /**
   * Object with userIDs for keys and true if they have accepted the TOS.
   */
  TermsAccepted = 'nmpTermsAccepted'
}

interface LogOutOpts {
  reloadApp?: boolean;
  reloadPath?: string;
}

/**
 * Response from `organization/config`, which is environment-specific.
 */
interface AppConfig {
  identityProviders: {
    name: string;
    baseUrl: string;
    ssoUrl: string;
  }[];
  showBeta?: boolean;
  showEvalMessage?: boolean;
  showRuo?: boolean;
}

/**
 * Response from `organization/tenantModes`.
 */
interface TenantModesConfig {
  defaultMode: string;
  modes: string[];
}

export const sessionRefreshInterval = 3 * 60 * 1000; // 3 minutes in milliseconds

@Injectable()
export class AuthService {
  static UNAVAILABLE = 'unavailable';
  static STORE_NAMESPACE = 'auth';
  static WEB_STORAGE_HIDE_PHI_KEY = 'hidePHI';

  public baseUrl: string;
  public ssoUrl: string;
  public showBetaMessage: boolean;
  public showEvalMessage: boolean;
  public showRuoMessage: boolean;
  public localeData: Localization;
  public helpConfig: HelpConfig;
  protected storedRedirectUrl: BrowserStoredValue<string>;
  protected storedTermsAccepted: BrowserStoredValue<object>;
  protected storedHidePHI: BrowserStoredValue<object>;
  private _currentUser = new BehaviorSubject<AuthUser>(null);
  private _currentUserLoadError = new BehaviorSubject<boolean>(false);
  private _currentUserLogoutError = new BehaviorSubject<boolean>(false);
  private _sessionRefreshSubscription: Subscription;
  private _userMapping = new BehaviorSubject<UserMapping>({});
  private _loadingRegulatoryModes = new BehaviorSubject<boolean>(false);
  private featureAccess = {
    trials: 'CLINICAL_TRIALS',
    trapp: 'TRAPP'
  };
  private _upgradeProgressing = false;
  private _canUpgradeSoftware = false;
  private boundHandleExternalHelpMessage: any;
  private _loadingUserMapping = false; // Prevent concurrent requests from being sent to the server (to load user data)
  isCaseRevisionFeatureEnabled$ = this.isFeatureEnabledOrRolePermitted$(
    IstFeatureFlags.CASE_REVISION
  );

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    browserStorageService: BrowserStorageService,
    private dialogService: DialogService
  ) {
    this.storedRedirectUrl = browserStorageService.proxy(
      BrowserStorageType.SESSION,
      AuthService.STORE_NAMESPACE,
      BrowserStoreKeys.RedirectUrl
    );
    this.storedTermsAccepted = browserStorageService.proxy(
      BrowserStorageType.LOCAL,
      AuthService.STORE_NAMESPACE,
      BrowserStoreKeys.TermsAccepted
    );
    this.storedHidePHI = browserStorageService.proxy(
      BrowserStorageType.LOCAL,
      AuthService.STORE_NAMESPACE,
      BrowserStoreKeys.HidePHI
    );

    this.boundHandleExternalHelpMessage = this.handleExternalHelpMessage.bind(this);
  }

  private _hidePHI = new BehaviorSubject<boolean>(false);

  get currentUser(): Observable<AuthUser> {
    return this._currentUser.asObservable();
  }

  get currentUserCanRuo(): Observable<boolean> {
    return this._currentUser.asObservable().pipe(map((currentUser) => _get(currentUser, 'canRuo')));
  }

  get isAuthenticated(): boolean {
    return !!this._currentUser.value;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this._currentUser.asObservable().pipe(map((currentUser) => !!currentUser));
  }

  get hidePHI(): Observable<boolean> {
    return this._hidePHI.asObservable();
  }

  get upgradeProgressing(): boolean {
    return this._upgradeProgressing;
  }

  get canUpgradeSoftware(): boolean {
    return this._canUpgradeSoftware;
  }

  get loadingRegulatoryModes(): Observable<boolean> {
    return this._loadingRegulatoryModes.asObservable();
  }

  get platformRefreshTokenRequest$(): Observable<PlatformRefreshSessionResponse> {
    // TODO: Remove this workaround once UI - feature flag is implemented properly
    return environment.platform
      ? this.http.get<any>(API_GATEWAY_URL.platformRefresh, {
          withCredentials: true
        })
      : of('');
  }

  /**
   * @returns List of the available HATEOAS links for the current user
   */

  get availableLinks$(): Observable<Links> {
    return this.currentUser.pipe(map((currentUser: AuthUser) => currentUser?._links));
  }

  /**
   * @returns List of the allowed feature flags and roles for the current user
   */
  get permittedIstFeatureFlagsAndRoles$(): Observable<Array<string | role>> {
    return this.currentUser.pipe(map((currentUser: AuthUser) => currentUser?.roles));
  }

  isFeatureEnabledOrRolePermitted$(featureFlagOrRoleName: string | role): Observable<boolean> {
    return this.permittedIstFeatureFlagsAndRoles$.pipe(
      map((featureFlagAndRolesList: Array<string | role>) =>
        featureFlagAndRolesList?.includes(featureFlagOrRoleName)
      )
    );
  }

  hasPermission$(permissionToVerify: string): Observable<boolean> {
    return this.availableLinks$.pipe(
      map((availableLinks: Links) => _has(availableLinks, permissionToVerify))
    );
  }

  // Should use hasPermission() prior to getURL() to verify
  // that the user has the corresponding link available
  getURL(linkKey: string, replacements?: Replacements): string {
    const link = this._currentUser.value._links[linkKey] as Link;

    if (!link) {
      // This should not happen.
      // It is to help with debugging, can remove later
      throw new Error(`Missing bootstrap link: ${linkKey}`);
    }

    let url = link.href;

    if (replacements) {
      url = CommonService.replaceInURL(url, replacements);
    }

    return url;
  }

  startSessionPolling(): void {
    this._sessionRefreshSubscription = interval(sessionRefreshInterval)
      .pipe(mergeMap(() => forkJoin([this.platformRefreshTokenRequest$, this.sessionRequest()])))
      .subscribe(([_, authUser]) => {
        if (authUser.anonymous) {
          this.logoutCurrentUser();
        }
      });
  }

  stopSessionPolling() {
    if (this._sessionRefreshSubscription) {
      this._sessionRefreshSubscription.unsubscribe();
    }
  }

  private sessionRequest(): Observable<AuthUser> {
    // TODO: Remove this workaround once UI - feature flag is implemented properly Or new session endpoint if fully integrated.
    return environment.platform
      ? this.http.get<AuthUser>(API_GATEWAY_URL.platformAuthSession)
      : this.http.get<AuthUser>(API_GATEWAY_URL.authSession);
  }

  private loadAppSettings(url: string): Observable<any> {
    return this.http.get<any>(url).pipe(
      tap((appSettings: any) => {
        // appSettings.dateFormat possible values: ["dd/MM/yyyy","MM/dd/yyyy","yyyy-MM-dd"]
        // appSettings.timeFormat possible values: ["hh:mm a","HH:mm"]
        const dateFormatOriginal = appSettings.dateFormat;
        // Luxon uses 'LL' for month (https://moment.github.io/luxon/#/parsing?id=table-of-tokens)
        const dateFormat = dateFormatOriginal.replaceAll('MM', 'LL');
        this.localeData = Object.assign(appSettings, {
          dateFormatOriginal,
          dateFormat,
          dateFormatShort: dateFormat === 'dd/LL/yyyy' ? 'dd/LL' : 'LL/dd',
          dateFormatMonth: dateFormat.includes('/') ? 'LL/yyyy' : 'yyyy-LL'
        });

        LocaleNumberPipe.registerDelimiters(
          appSettings.thousandSeparator,
          appSettings.decimalPlace
        );
        DateFormatPipe.setDefaultTimezone(appSettings.timeZone);
      })
    );
  }

  loadHelpConfig(url: string): Observable<HelpConfig> {
    return this.http.get<HelpConfig>(url).pipe(
      tap((response: HelpConfig) => {
        this.helpConfig = response;
      })
    );
  }

  checkUpgradeStatus(url: string): Observable<any> {
    return this.http.get<any>(url).pipe(
      tap((json: any) => {
        this._upgradeProgressing = upgradeProgressing(json.status);
        this._canUpgradeSoftware = _has(json, ['_links', 'start']);
      })
    );
  }

  loadCurrentUser(): Promise<any> {
    return this.sessionRequest()
      .pipe(
        tap((authUser: AuthUser) => {
          this._currentUserLoadError.next(false);
          if (authUser.anonymous) {
            this.setRedirectURL(window.location.pathname + window.location.search); // save initially requested path
          } else {
            const hidePHIFlags = this.getHidePHIFlags();
            this._hidePHI.next(!!hidePHIFlags[authUser.userId]);
          }
          this._currentUser.next(authUser.anonymous ? null : authUser);
          this.refreshUserMapping(); // This may take several seconds: can be a slow query.
        }),
        mergeMap(() => {
          const observables = [];

          if (this.hasPermission(SessionLinkKeys.VIEW_APP_SETTINGS)) {
            observables.push(this.loadAppSettings(this.getURL(SessionLinkKeys.VIEW_APP_SETTINGS)));
          }

          if (this.hasPermission(SessionLinkKeys.HELP_PLUGIN)) {
            observables.push(this.loadHelpConfig(this.getURL(SessionLinkKeys.HELP_PLUGIN)));
          }

          if (this.hasPermission(SessionLinkKeys.VIEW_UPGRADE_STATUS)) {
            observables.push(
              this.checkUpgradeStatus(this.getURL(SessionLinkKeys.VIEW_UPGRADE_STATUS))
            );
          }

          return forkJoin(observables);
        }),
        catchError((err) => {
          this._currentUserLoadError.next(true);
          throw err;
        })
      )
      .toPromise();
  }

  loadAuthConfig(): Promise<any> {
    return this.http
      .get<any>(API_GATEWAY_URL.authConfig)
      .toPromise()
      .then((config: AppConfig) => {
        this.showBetaMessage = config.showBeta;
        this.showEvalMessage = config.showEvalMessage;
        this.showRuoMessage = config.showRuo;
        const oktaProvider = config.identityProviders.find((i) => i.name === 'okta');
        this.baseUrl = oktaProvider.baseUrl;
        this.ssoUrl = oktaProvider.ssoUrl;
      });
  }

  /**
   * Removes the 'notificationAcknowledged' item from local storage.
   * This function is typically used to reset the notification acknowledgement state.
   * It's associated with the logout flow that's why keepting it here
   */
  removeNotificationAcknowledgement(): void {
    localStorage.removeItem('notificationAcknowledged');
  }

  logoutCurrentUser(options: LogOutOpts = {}) {
    if (!this.isAuthenticated) {
      return;
    }

    this.toasterService.clearAll();
    this.dialogService.closeAllDialogs();
    this.removeNotificationAcknowledgement();

    if (!environment.platform) {
      // Create a dummy widget that is not rendered to the page
      // and call authClient.closeSession() to also log the user out of Okta
      // https://github.com/okta/okta-auth-js/blob/master/README.md#closesession
      this.buildDummyWidget()
        .authClient.closeSession()
        .catch((err) => {
          console.log('AuthService - Failed to log user out of Okta: ', err);
        });

      return this.http.delete(API_GATEWAY_URL.authSession).subscribe({
        next: () => this.logoutCleanupTasks(options),
        error: () => this._currentUserLogoutError.next(true)
      });
    } else {
      this.logoutCleanupTasks(options);
    }
  }

  private logoutCleanupTasks(options) {
    this.stopSessionPolling();
    this.clearRedirectURL();
    this._currentUserLogoutError.next(false);
    if (this.isAuthenticated) {
      this.setHidePHIFlag(false);
      this._currentUser.next(null);
    }
    if (options.reloadApp) {
      CommonService.reloadApp(options.reloadPath);
    }
  }

  popRedirectURL(): string {
    const val = this.storedRedirectUrl.read() || sessionStorage.getItem(REDIRECT_URL_LEGACY_1_0); // Also check for a 1.0 style redirect URL
    this.clearRedirectURL();
    return val;
  }

  clearRedirectURL() {
    this.storedRedirectUrl.remove();
    sessionStorage.removeItem(REDIRECT_URL_LEGACY_1_0);
  }

  setRedirectURL(val: string) {
    if (val) {
      this.storedRedirectUrl.write(val);
    } else {
      this.clearRedirectURL();
    }
  }

  getTermsStatus() {
    const currentUserId = this._currentUser.value && this._currentUser.value.userId;
    const termsAccepted = this.storedTermsAccepted.read() || {};
    return currentUserId && termsAccepted[currentUserId];
  }

  acceptTerms() {
    const currentUserId = this._currentUser.value && this._currentUser.value.userId;
    if (currentUserId) {
      const termsAccepted = this.storedTermsAccepted.read() || {};
      termsAccepted[currentUserId] = true;
      this.storedTermsAccepted.write(termsAccepted);
    }
  }

  hasPermission(permissionToVerify: string): boolean {
    return _has(this._currentUser.value, ['_links', permissionToVerify]);
  }

  private hasFeatureAccess(featureToVerify: string): boolean {
    return (
      this._currentUser.value &&
      this._currentUser.value.features &&
      this._currentUser.value.features.includes(featureToVerify)
    );
  }

  hasTrialsAccess(): boolean {
    return this.hasFeatureAccess(this.featureAccess.trials);
  }

  hasTrappAccess(): boolean {
    return this.hasFeatureAccess(this.featureAccess.trapp);
  }

  private userMappingRequest(): Observable<UserMapping> {
    return this.http.get<UserMapping>(this.getURL(SessionLinkKeys.LIST_USER_NAMES));
  }

  // Updates the map of all users. Is async.
  // Runs only if the current user has the 'listUserNames' permission.
  // Only one such update can be performed at a time.
  refreshUserMapping(): void {
    if (this.hasPermission(SessionLinkKeys.LIST_USER_NAMES) && !this._loadingUserMapping) {
      this._loadingUserMapping = true;
      this.userMappingRequest()
        .pipe(finalize(() => (this._loadingUserMapping = false)))
        .subscribe((mapping) => {
          this._userMapping.next(mapping);
        });
    }
  }

  getUserInfo(uuid: string, field: string): Observable<string> {
    if (!uuid) {
      return of(AuthService.UNAVAILABLE);
    }

    return this._userMapping.asObservable().pipe(
      map((userMapping) => _get(userMapping, [uuid, field], AuthService.UNAVAILABLE)),
      distinctUntilChanged()
    );
  }

  getUserFullName(uuid: string): Observable<string> {
    return this.getUserInfo(uuid, 'fullName');
  }

  getUserName(uuid: string): Observable<string> {
    return this.getUserInfo(uuid, 'username');
  }

  buildDummyWidget() {
    // this is not intended to be rendered to the page
    return new OktaSignIn({ baseUrl: this.baseUrl });
  }

  private getHidePHIFlags() {
    return this.storedHidePHI.read() || {};
  }

  togglePHI(): void {
    this.setHidePHIFlag(!this._hidePHI.value);
  }

  setHidePHIFlag(newVal: boolean) {
    const currentUserId = this._currentUser.value && this._currentUser.value.userId;
    if (currentUserId) {
      const hidePHIFlags = this.getHidePHIFlags();
      if (!newVal) {
        delete hidePHIFlags[currentUserId];
        this._hidePHI.next(false);
      } else {
        hidePHIFlags[currentUserId] = true;
        this._hidePHI.next(true);
      }
      this.storedHidePHI.write(hidePHIFlags);
    }
  }

  loadAvailableTenantModes(): Observable<TenantModesConfig> {
    this._loadingRegulatoryModes.next(true);
    return this.http.get<TenantModesConfig>(API_GATEWAY_URL.tenantModes).pipe(
      filter(
        (modes: TenantModesConfig) =>
          modes != null && modes.defaultMode != null && Array.isArray(modes.modes)
      ),
      finalize(() => this._loadingRegulatoryModes.next(false))
    );
  }

  openHelpLink(target = '_blank') {
    window.open(this.helpConfig.publicationUrl, target);
    window.addEventListener('message', this.boundHandleExternalHelpMessage);
  }

  handleExternalHelpMessage(event: any) {
    if (
      this.helpConfig.publicationUrl.indexOf(event.origin) === 0 &&
      event.data === 'headlessLoginReady'
    ) {
      event.source.postMessage(
        { UserName: this.helpConfig.username, Password: this.helpConfig.password },
        this.helpConfig.publicationUrl
      );

      window.removeEventListener('message', this.boundHandleExternalHelpMessage);
    }
  }
}
