export interface ListItemName {
  id: number | string;
  name: string;
}

export interface PertinentNegative {
  diagnosis: ListItemName;
  biomarkers: ListItemName[];
}
export interface PertinentNegativeMigration {
  diseaseId: string;
  name: string;
  pertinentNegatives: {
    compatibleWithCurrentDiagnosis: boolean;
    id: string;
    name: string;
  }[];
}

export class PNItemNode {
  parent: PNItemNode;
  children: PNItemNode[];
  id: number | string;
  value: string;
  level?: number;

  constructor(id: number | string, value: string, parent: PNItemNode, children: PNItemNode[]) {
    this.id = id;
    this.value = value;
    this.parent = parent;
    this.children = children;
  }

  static emptyDisease() {
    return new this(null, '', null, []);
  }

  static emptyBiomarker(parent: PNItemNode) {
    return new this(null, '', parent, null);
  }
}
