export enum PipelineInputFileFormat {
  BAM = 'BAM',
  BED = 'BED',
  BEDPE = 'BEDPE',
  CSV = 'CSV',
  JSON = 'JSON',
  TSV = 'TSV',
  VCF = 'VCF'
}

export interface SecondaryAnalysisFileFormat {
  id?: string;
  fileIndex?: number;
  fileFormatId: string; // UUID
  title?: string;
  format: PipelineInputFileFormat;
  numFiles?: number;
  specific?: boolean; // If false, vcf is generic.
  supportedBiomarkerTypes: Array<BiomarkerType>;
  _links?: any;
}

export interface BiomarkerType {
  name: string;
  excluded?: boolean;
  vcfFieldMappingsRequirementSuppressed?: boolean;
  vcfHardFilteringNotSupported?: boolean;
  vcfCnvHardFilteringSupported?: boolean;
  filter?: {};
  filterableProperties?: FilterableProperties;
  vcfFieldMappings?: {};
  defaultVcfFieldMappings?: {};
  defaultVcfCnvFieldMappings?: {
    vcfFieldForCopyNumber?: string;
    vcfFieldForFoldChange?: string;
  };
  vcfCnvFieldMappings?: {
    vcfFieldForCopyNumber?: string;
    vcfFieldForFoldChange?: string;
  };
  vcfHardFiltering?: {
    requiredFilterColumnValues?: string[] | null;
  };
}

export interface FilterableProperties {
  [key: string]: Array<string>;
}

// These file formats have been deprecated in nMP 2.6+, if an assay contains these fileFormatIds it means they are no longer compatible and we should show an error.
// TODO: Replace these hardcoded ids with api call: https://rds-csi.atlassian.net/browse/ISTN-11664
export const DEPRECATED_FILE_FORMAT_IDS = [
  '78badc6e-ad81-45c5-a177-7bca9f757080',
  '356b12e6-ac87-11ea-bb37-0242ac130002'
];
