<mat-toolbar oneTopbar class="header no-print">
  <mat-toolbar-row class="page-container header-container">
    <div class="header-section">
      <ng-container *ngIf="(isAuthorized$ | async)">
        <ng-container *ngIf="(backLink$ | async) as backLink">
          <a *ngIf="backLink !== 'homeIcon'" class="header-item one-link-no-style" [routerLink]="backLink">
            <mat-icon>arrow_leftwards</mat-icon>
          </a>
          <a *ngIf="backLink === 'homeIcon'" class="header-item one-link-no-style" routerLink="/">
            <mat-icon>home</mat-icon>
          </a>
        </ng-container>
      </ng-container>
      <div one-topbar-title class="header-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <a *ngIf="!(navBarService.productNameHidden | async)" class="header-title one-link-no-style"
            [class.not-authorized]="!(isAuthorized$ | async)" [routerLink]="(isAuthorized$ | async) ? '/' : null">
            <app-name></app-name>
          </a>
          <ng-container *ngIf="(subTitle$ | async) as subTitle">
            <span *ngIf="!(navBarService.productNameHidden | async)" class="header-separator" ngPreserveWhitespaces> | </span>
            <span class="header-subtitle" [ngSwitch]="subTitle.id">
              <ng-container *ngSwitchCase="'assayID'" i18n="HeaderBar.Notes@@assay-id">Assay ID</ng-container>
              <ng-container *ngSwitchCase="'caseID'">
                <app-ruo-ivd-mapper>
                  <ng-container IVD i18n="HeaderBar.Notes@@header-bar-case-id">Case ID</ng-container>
                  <ng-container RUO i18n="HeaderBar.Notes@@header-bar-research-case-id">Research case ID</ng-container>
                </app-ruo-ivd-mapper>
              </ng-container>
              <ng-container>: {{subTitle.value}}</ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="additionalData$ | async as additionalData">
            <div *ngIf="additionalData?.virtualGenePanels?.length > 0" fxLayout="row" fxLayoutAlign="start center">
              <span class="header-separator" ngPreserveWhitespaces> | </span>
              <mat-form-field oneFormField>
                <mat-select oneSelect size="small" class="full-width" [(ngModel)]="additionalData.selectedGenePanelId"
                  (selectionChange)="updateVirtualGenePanel($event, additionalData.caseId)" [disabled]="!additionalData.canEdit">
                  <mat-option value="None" i18n="HeaderBar.DropdownOption@@no-virtual-gene-panel">No virtual gene panel</mat-option>
                  <mat-option *ngFor="let genePanelGroup of additionalData.virtualGenePanels;" [value]="genePanelGroup.id">
                    {{genePanelGroup.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div class="header-section">
      <ng-container *ngIf="showNotifications$ | async">
        <owc-icon-button flat icon="notification" id="notification-message-icon" (click)="notificationMessagePopup.open()">
        </owc-icon-button>
        <owc-tooltip anchor="notification-message-icon" i18n="HeaderBar.Link.OwcTooltip@@notifications">Notifications</owc-tooltip>
      </ng-container>
      <a one-icon-button class="header-item one-link-no-style" i18n-matTooltip="HeaderBar.Link.MatTooltip@@search"
        matTooltip="Search" *ngIf="isSearchCasePermissionGet$ | async" oneTooltip [routerLink]="'/search'">
        <mat-icon>search</mat-icon>
      </a>
      <a one-icon-button class="header-item one-link-no-style" i18n-matTooltip="HeaderBar.Link.MatTooltip@@analytics"
        matTooltip="Analytics" *ngIf="isOperationalAnalyticsPermissionGet$ | async" oneTooltip [routerLink]="'/analytics'">
        <mat-icon svgIcon="analytics"></mat-icon>
      </a>
      <app-settings-menu class="header-item" *ngIf="(isAuthorized$ | async)"
        i18n-matTooltip="HeaderBar.SettingsMenu.MatTooltip@@your-account" matTooltip="Your account"
        [userFullName]="(currentUser$ | async)?.userFullName" [email]="(currentUser$ | async)?.userEmail" oneTooltip></app-settings-menu>
      <img alt="Roche logo" i18n-alt="HeaderBar.RocheLogo@@roche-logo" class="roche-logo header-item" src="assets/images/Roche_Logo.svg" />
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Notification message popup component with a template reference variable -->
<app-notification-message-popup class="no-print"></app-notification-message-popup>
