// SharedModule with the components, directives, and pipes that you use everywhere in your app.
// This module should consist entirely of declarations, most of them exported.
// The SharedModule should not have providers for reasons explained previously.
// Nor should any of its imported or re-exported modules have providers. If you deviate from this guideline, know what you're doing and why.
// Import the SharedModule in your feature modules, both those loaded when the app starts and those you lazy load later.

// https://angular.io/guide/ngmodule-faq#sharedmodule

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToasterModule } from 'angular2-toaster';
import { ToasterComponent } from './toaster/toaster.component';

import { AvatarComponent } from './avatar/avatar.component';
import { DoughnutsPercentageComponent } from './doughnut-percentage/doughnut-percentage.component';
import { MaterialModule } from './material/material.module';
import { OneDesignModule } from './one-design.module';
import { AppNameComponent } from './app-name/app-name.component';
import { CustomBreakPointsProvider } from './responsive-breakpoints/responsive-breakpoints';
import { DateInputComponent } from './date-input/date-input.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { TherapyChipComponent } from './therapy-chip/therapy-chip.component';
import { TherapyResponseComponent } from './therapy-response/therapy-response.component';
import { CaseSummaryInputComponent } from './case-summary-input/case-summary-input.component';
import { CandeactivateGuard } from '../guards/deactivate.guard';
import { LastSavedComponent } from './last-saved/last-saved.component';
import { TierNameComponent } from './tier-name/tier-name.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AvatarPicComponent } from './avatar-pic/avatar-pic.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { CombinationTagComponent } from './combination-tag/combination-tag.component';
import { VariantTitleComponent } from './variant-title/variant-title.component';
import { SaveErrorMessageComponent } from './save-error-message/save-error-message.component';
import { CountryInputComponent } from './country-input/country-input.component';
import { VariantFilterComponent } from './variant-filter/variant-filter.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { VariantOncogenicityComponent } from './variant-oncogenicity/variant-oncogenicity.component';
import { StructuralVariantTitleComponent } from './variant-title/structural-variant-title';
import { PopupWindowComponent } from './popup-window/popup-window.component';
import { DialogComponent } from './popup-window/dialog.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { SystemUnavailablePageComponent } from './system-unavailable-page/system-unavailable-page.component';
import { TimezoneDisplayComponent } from './timezone-display/timezone-display.component';
import { TranslationComponent } from 'app/shared/translation/translation.component';
import { VariantPinIconComponent } from './variant-pin-icon/variant-pin-icon.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PertinentNegativeChipComponent } from './pertinent-negative-chip/pertinent-negative-chip.component';
import { DoughnutPercentagesComponent } from './doughnut-percentages/doughnut-percentages.component';
import { SquarePercentageComponent } from './square-percentage/square-percentage.component';
import { BarNumbersComponent } from './bar-numbers/bar-numbers.component';
import { CaseEventNotificationComponent } from './case-event-notification/case-event-notification.component';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { PipeModule } from './../pipes/pipe.module';
import { VariantFrequencyPanelComponent } from './variant-frequency-panel/variant-frequency-panel.component';
import { RocheNetworkVFPanelComponent } from './roche-network-vf-panel/roche-network-vf-panel.component';
import { VariantLastNameComponent } from './variant-title/variant-last-name';
import { LastUpdatedComponent } from './last-updated/last-updated.component';
import { TierDescriptionComponent } from './tier-description/tier-description.component';
import { UserNameComponent } from './user-name/user-name.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { BiomarkerFileReaderFileComponent } from './biomarker-reader-file/biomarker-reader-file.component';
import { DirectiveModule } from 'app/directives/directive.module';
import { FilterPopupComponent } from './popup-window/filter-popup.component';
import { DefaultBiomarkerFilterTextComponent } from './default-biomarker-filter-text/default-biomarker-filter-text.component';
import { FusionPartnerNameComponent } from './fusion-partner-name/fusion-partner-name.component';
import { BreakpointPopupComponent } from './popup-window/breakpoint-popup/breakpoint-popup.component';
import { VariantDisplayFieldTypeComponent } from './variant-display-field-type/variant-display-field-type.component';
import { VariantDisplayFieldValueComponent } from './variant-display-field-value/variant-display-field-value.component';
import { DisclaimerFirstLineComponent } from './disclaimer-first-line/disclaimer-first-line.component';
import { AppErrorIconComponent } from './app-error-icon/app-error-icon.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { ErrorBarComponent } from './error-bar/error-bar.component';
import { OneWebComponentsAngularModule } from '@one/angular';
import { GenericOwcMenuComponent } from './generic-owc-menu/generic-owc-menu.component';
import { RuoPopupComponent } from './popup-window/ruo-popup/ruo-popup.component';
import { RuoLinkComponent } from './ruo-link/ruo-link.component';
import { RuoIvdMapperComponent } from './ruo-ivd-mapper/ruo-ivd-mapper.component';
import { CopyTextDialogComponent } from './copy-text-dialog/copy-text-dialog.component';
import { TabsWithNavigationComponent } from './tabs-with-navigation/tabs-with-navigation.component';
import { NotificationMessagePopupComponent } from './popup-window/notification-message-popup/notification-message-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    OneDesignModule,
    FormsModule,
    ReactiveFormsModule,
    ToasterModule.forRoot(),
    RouterModule,
    FlexLayoutModule,
    PipeModule,
    DirectiveModule,
    OneWebComponentsAngularModule
  ],
  declarations: [
    AvatarComponent,
    VariantFrequencyPanelComponent,
    DoughnutsPercentageComponent,
    DateInputComponent,
    ToasterComponent,
    LoadingOverlayComponent,
    TherapyChipComponent,
    TherapyResponseComponent,
    LastSavedComponent,
    LastUpdatedComponent,
    TierNameComponent,
    TierDescriptionComponent,
    CaseSummaryInputComponent,
    NotFoundPageComponent,
    AvatarPicComponent,
    SettingsMenuComponent,
    CombinationTagComponent,
    VariantTitleComponent,
    SaveErrorMessageComponent,
    CountryInputComponent,
    VariantFilterComponent,
    ReportHeaderComponent,
    VariantOncogenicityComponent,
    StructuralVariantTitleComponent,
    PopupWindowComponent,
    DialogComponent,
    ForbiddenPageComponent,
    SystemUnavailablePageComponent,
    TimezoneDisplayComponent,
    TranslationComponent,
    VariantPinIconComponent,
    NumberInputComponent,
    PertinentNegativeChipComponent,
    DoughnutPercentagesComponent,
    SquarePercentageComponent,
    CaseEventNotificationComponent,
    BarNumbersComponent,
    RocheNetworkVFPanelComponent,
    AutocompleteInputComponent,
    VariantLastNameComponent,
    AppNameComponent,
    UserNameComponent,
    HeaderBarComponent,
    FilterPopupComponent,
    BiomarkerFileReaderFileComponent,
    DefaultBiomarkerFilterTextComponent,
    BreakpointPopupComponent,
    FusionPartnerNameComponent,
    VariantDisplayFieldTypeComponent,
    VariantDisplayFieldValueComponent,
    DisclaimerFirstLineComponent,
    AppErrorIconComponent,
    FooterBarComponent,
    ErrorBarComponent,
    GenericOwcMenuComponent,
    RuoPopupComponent,
    RuoLinkComponent,
    RuoIvdMapperComponent,
    CopyTextDialogComponent,
    TabsWithNavigationComponent,
    NotificationMessagePopupComponent
  ],
  exports: [
    OneDesignModule,
    CommonModule,
    FormsModule,
    OneDesignModule,
    ReactiveFormsModule,
    RouterModule,
    AvatarComponent,
    MaterialModule,
    FlexLayoutModule,
    PipeModule,
    DirectiveModule,
    DoughnutsPercentageComponent,
    DateInputComponent,
    ToasterComponent,
    LoadingOverlayComponent,
    TherapyChipComponent,
    TherapyResponseComponent,
    LastSavedComponent,
    LastUpdatedComponent,
    TierNameComponent,
    TierDescriptionComponent,
    CaseSummaryInputComponent,
    NotFoundPageComponent,
    AvatarPicComponent,
    SettingsMenuComponent,
    CombinationTagComponent,
    VariantTitleComponent,
    SaveErrorMessageComponent,
    CountryInputComponent,
    VariantFilterComponent,
    VariantFrequencyPanelComponent,
    ReportHeaderComponent,
    VariantOncogenicityComponent,
    StructuralVariantTitleComponent,
    PopupWindowComponent,
    DialogComponent,
    ForbiddenPageComponent,
    SystemUnavailablePageComponent,
    TimezoneDisplayComponent,
    TranslationComponent,
    VariantPinIconComponent,
    NumberInputComponent,
    PertinentNegativeChipComponent,
    DoughnutPercentagesComponent,
    SquarePercentageComponent,
    CaseEventNotificationComponent,
    BarNumbersComponent,
    RocheNetworkVFPanelComponent,
    AutocompleteInputComponent,
    VariantLastNameComponent,
    AppNameComponent,
    UserNameComponent,
    HeaderBarComponent,
    FilterPopupComponent,
    BiomarkerFileReaderFileComponent,
    DefaultBiomarkerFilterTextComponent,
    BreakpointPopupComponent,
    FusionPartnerNameComponent,
    VariantDisplayFieldTypeComponent,
    VariantDisplayFieldValueComponent,
    DisclaimerFirstLineComponent,
    AppErrorIconComponent,
    FooterBarComponent,
    ErrorBarComponent,
    OneWebComponentsAngularModule,
    GenericOwcMenuComponent,
    RuoPopupComponent,
    RuoLinkComponent,
    RuoIvdMapperComponent,
    CopyTextDialogComponent,
    TabsWithNavigationComponent,
    NotificationMessagePopupComponent
  ],
  providers: [CandeactivateGuard, CustomBreakPointsProvider],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
