import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, takeUntil, tap } from 'rxjs';

import { AuthService } from 'app/services/auth.service';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-notification-message-popup',
  templateUrl: './notification-message-popup.component.html',
  styleUrls: ['./notification-message-popup.component.scss']
})
export class NotificationMessagePopupComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  visible: boolean = false;
  notificationMessages$ = this.notificationService.notificationMessages$;
  isNotificationLoading$ = this.notificationService.isNotificationLoading$;

  constructor(private notificationService: NotificationService, private authService: AuthService) {}

  ngOnInit(): void {
    this.autoOpenAndCloseNotificationPopup();
  }

  autoOpenAndCloseNotificationPopup(): void {
    combineLatest([
      this.authService.isAuthenticated$,
      this.notificationService.isNotificationAcknowledged$
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(([isAuthenticated, isNotificationAcknowledged]) => {
          if (isAuthenticated && !isNotificationAcknowledged) {
            this.open();
            return;
          }

          this.close();
        })
      )
      .subscribe();
  }

  close(): void {
    this.visible = false;
    if (this.authService.isAuthenticated) {
      this.notificationService.dismissNotificationsEvent();
      this.notificationService.setNotificationAcknowledgement();
    }
  }

  open(): void {
    this.notificationMessages$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((notifications) => {
      this.visible = notifications?.length > 0;
    });
    this.notificationService.loadNotifications();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
